
function headerScroll(){
	var divPos = $(".header").offset().top;
	var windowHeight = $(window).height();

	if (divPos >= windowHeight / 2 ) {
		$(".header").addClass("header--dark");
	} else {
		$(".header").removeClass("header--dark");
	}
}

function navSlide(){
	if ($(window).width() < 1120) {
		var ScrollTop = $(window).scrollTop();
		$(".header__nav").css('top', ScrollTop);
	 }
}

function modal() {
	$("[href='modal:contact']").addClass("js-contact-modal-toggle");
	$("[href='modal:contact']").attr("href","#top");

	$(".js-contact-modal-toggle").click(function() {
		if ($(".contact-modal").hasClass("contact-modal--active")) {
			$(".contact-modal").removeClass("contact-modal--active");
		} else {
			$(".contact-modal").addClass("contact-modal--active");
		}

		if ($("body").hasClass("nav-slide")) {
			$("body").removeClass("nav-slide");
			$(".header__nav-trigger--open").removeClass("hidden");
		}
	});


}



$(document).ready(function(){
	headerScroll();
	modal();

	$('.js-nav-trigger').click(function() {
		$('body').toggleClass('nav-slide');
		$('.header__nav-trigger--open').toggleClass('hidden');
	});


	$('.js-slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: '.js-slider-nav',
		autoplay: true,
		autoplaySpeed: 3500,
		speed: 500,
		cssEase: 'linear'
	  });

	  $('.js-slider-nav').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		asNavFor: '.js-slider',
		dots: true,
		fade: true,
		focusOnSelect: true,
		speed: 500,
		cssEase: 'linear'
	  });
});

$(window).scroll(function(){  
	headerScroll();
	navSlide();
});
  
$(window).resize(function(){
	headerScroll();
});